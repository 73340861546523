
function Error() {
  return (
    <div>
      Error 404!
    </div>
  );
}

export default Error;
