import './Navigation.css';
import React, { useState } from 'react';

function Navigation() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  const navbarClass = `navbar-collapse ${isNavOpen ? 'collapse show' : 'collapse'}`;

  return (
    <>
      {/* <!-- =========Fixed Navigation=========== --> */}
      <header id="navigation" class="navigation">
        <div class="container">
          <div class="navbar-header w-100">
            <nav class="navbar navbar-expand-lg navbar-dark px-0">
              {/* <!-- logo --> */}
              <a className="navbar-brand logo" href="/">
                {/* <!-- <img src="images/logo.png" alt="Website Logo" /> --> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50">
                  <circle fill="black" cx="25" cy="25" r="25" />
                  <circle fill="#401404" cx="25" cy="25" r="22.5" />
                  <path
                    fill="url(#id0)"
                    d="M13.42 30.84c-0.55-10.26 13.89-16.78 9.84-24.66 3.17 2.97 5.07 8.17 8.23 12.67 3.23 4.6 5.39 9.66 5.07 12.44-1.77 15.64-22.25 16.14-23.14-0.45"
                  />
                  <path
                    fill="url(#id1)"
                    d="M13.74 30.99c0.01 0 5.88 7.88 6.8 8.43-3.9-8.68 1.93-11.87 3.67-18.56 0.27 2.08 1.39 3.36 4.58 7.88 2.03 2.88 2.51 7.62 1.04 10.71 2.86-1.81 6.07-7.25 6.76-10.55 0.16 19.58-22.76 18.01-22.85 2.09"
                  />
                  <path fill="url(#id2)" d="M25.45 43.82c-3.82-3.38-2.98-10.16-0.2-13.88 5.49 11.16 0.4 12.25 0.2 13.88" />
                  <defs>
                    <linearGradient id="id1" gradientUnits="userSpaceOnUse" x1="24.85" y1="20.86" x2="25.48" y2="43.25">
                      <stop offset="0" stopColor="#F6AF2B" />
                      <stop offset="1" stopColor="#FDFEF7" />
                    </linearGradient>
                    <linearGradient id="id2" gradientUnits="userSpaceOnUse" x1="27.38" y1="30.56" x2="23.28" y2="43.2">
                      <stop offset="0" stopColor="#172629" />
                      <stop offset="1" stopColor="#265863" />
                    </linearGradient>
                    <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="26.13" y1="43.1" x2="23.87" y2="6.23">
                      <stop offset="0" stopColor="#FBA61C" />
                      <stop offset="0.45098" stopColor="#E36C34" />
                      <stop offset="1" stopColor="#F3AE75" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
              {/* <!-- /logo --> */}

              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar01"
                aria-controls="navbarSupportedContent" aria-expanded={isNavOpen ? 'true' : 'false'} aria-label="Toggle navigation"
                onClick={toggleNavbar}>
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class={navbarClass} id="navbar01">
                <ul class="navbar-nav navigation-menu ml-auto">
                  <li class="nav-item">
                    <a class="nav-link" href="/#home">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#about">About</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#services">Services</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#portfolio">Portfolio</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#our-team">Team</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#pricing">Pricing</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#blog">Blog</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/#contact-us">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {/* <!-- =========End Sticky Navigation========== --> */}
    </>
  );
}

export default Navigation;
