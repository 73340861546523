
function CheckoutRoute() {
  return (
    <div>
      CHECKOUT
    </div>
  );
}

export default CheckoutRoute;
