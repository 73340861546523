
function CustomRoute() {
  return (
    <div>
      CUSTOM
    </div>
  );
}

export default CustomRoute;
