import React, { useEffect, useState } from 'react';
import Utils from '../../Utils';  // Substitua pelo caminho correto do seu arquivo Utils

import Navigation from "../comp/01-navigation/Navigation";
import Header from "../comp/02-header/Header";

function HomeRoute() {
  const [isConnected, setIsConnected] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const isConnected = await Utils.checkConnection();
        setIsConnected(isConnected);
      } catch (error) {
        console.error('Erro ao verificar a conexão:', error);
        setIsConnected(false);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      {isConnected !== null && (
        <p>
          {isConnected ?
            <div>
              <Navigation />
              <Header />
            </div>
            : 'Não conectado à API'}
        </p>
      )}
    </div>
  );
}

export default HomeRoute;
