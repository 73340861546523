class Utils {
  // URL base da API
  static api_url = null;
  static url_sf = 'https://worker-solitary-dream-b9c0.lollerrmns.workers.dev/sf/';

  /**
   * Define a URL da API.
   * @param {string} value - A URL da API.
   */
  static setApiUrl(value) {
    Utils.api_url = value;
  }

  /**
   * Define assincronamente a URL da API.
   * @param {string} value - A URL da API.
   */
  static async setApiUrlAsync(value) {
    console.log("setApiUrl:" + value);
    Utils.api_url = value;
  }

  /**
   * Obtém assincronamente a URL da API, verificando-a se ainda não estiver definida.
   * @returns {string} - A URL da API.
   */
  static async getApiUrlAsync() {
    console.log("checkApiUrl:");
    try {
      if (!Utils.api_url) {
        const responseSF = await fetch(Utils.url_sf);
        if (responseSF.ok) {
          const data = await responseSF.text();
          Utils.setApiUrlAsync(data.trim());
        } else {
          console.error('Erro ao verificar a URL da API:', responseSF.status);
        }
      }
      return Utils.api_url || '';
    } catch (error) {
      console.error('Erro ao verificar a URL da API:', error);
      return '';
    }
  }

  /**
   * Obtém a URL da API, verificando-a se ainda não estiver definida.
   * @returns {string} - A URL da API.
   */
  static async getApiUrl() {
    if (!Utils.api_url) {
      await Utils.getApiUrlAsync();
    }
    return Utils.api_url || '';
  }

  /**
   * Realiza uma chamada GET à API.
   * @param {string} path - O endpoint da API.
   * @param {object} headers - Os headers extras da requisição.
   * @returns {Response} - A resposta da chamada GET.
   * @throws {Error} - Em caso de erro na chamada GET.
   */
  static async callGET(path, headers) {
    try {
      console.log("-----callGET()");
      console.log("-----callGET()path:"+path);
      console.log("-----callGET()headers:"+headers);
      const apiUrl = await Utils.getApiUrl();
      console.log("-----callGET()apiUrl:"+apiUrl);
      const endpoint = apiUrl + path;
      console.log("-----callGET()endpoint:"+JSON.stringify(endpoint));
      
      let requestHeaders = {
        'User-Agent': 'Godzilla1.0',
      };

      if (headers) {
        requestHeaders = { ...requestHeaders, ...headers };
      }
      console.log("-----callGET()requestHeaders:"+JSON.stringify(requestHeaders));
      
      const responseSF = await fetch(endpoint, {
        headers: requestHeaders,
      });
      console.log("-----callGET()responseSF:"+JSON.stringify(responseSF));
      
      if (!responseSF.ok) {
        console.log("-----callGET()!responseSF.ok:");
        throw new Error('Erro na chamada GET à API');
      }
      console.log("-----callGET()return:"+JSON.stringify(responseSF));
      
      return responseSF;

    } catch (error) {
      console.error('Erro na chamada GET:', error);
      throw error;
    }
  }

  /**
   * Realiza uma chamada POST à API.
   * @param {string} path - O endpoint da API.
   * @param {object} body - Os dados a serem enviados no corpo da requisição (JSON).
   * @param {object} headers - Os headers extras da requisição.
   * @returns {Response} - A resposta da chamada POST.
   * @throws {Error} - Em caso de erro na chamada POST.
   */
  static async callPOST(path, body, headers = null) {
    try {
      const apiUrl = await Utils.getApiUrl();
      const endpoint = apiUrl + path;

      let requestHeaders = {
        'User-Agent': 'Godzilla1.0',
      };

      if (headers) {
        requestHeaders = { ...requestHeaders, ...headers };
      }

      const responseSF = await fetch(endpoint, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(body),
      });

      if (!responseSF.ok) {
        throw new Error('Erro na chamada POST à API');
      }

      return responseSF;

    } catch (error) {
      console.error('Erro na chamada POST:', error);
      throw error;
    }
  }

  /**
   * Verifica a conexão com a API fazendo uma chamada GET à URL base.
   * @returns {boolean} - `true` se a conexão for bem-sucedida, `false` caso contrário.
   */
  static async checkConnection() {
    try {
      const response = await Utils.callGET('/check-status');

      return response.ok;

    } catch (error) {
      return false;
    }
  }

  /**
   * Realiza o upload de um arquivo usando multipart form data.
   * @param {File} file - O arquivo a ser enviado.
   * @returns {Promise<Response>} - A resposta da chamada POST.
   * @throws {Error} - Em caso de erro no upload.
   */
  static async uploadFileMultipartFormData(file) {
    try {
      const apiUrl = await Utils.getApiUrlAsync();
      const endpoint = apiUrl + '/upload_files';

      const formData = new FormData();
      formData.append('file', file);

      console.log("uploadFileMultipartFormData:" + apiUrl);

      const responseSF = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: {
          'User-Agent': 'Godzilla1.0',
        },
      });

      if (!responseSF.ok) {
        throw new Error('Erro durante o upload');
      }

      return responseSF;
    } catch (error) {
      console.error('Erro durante o upload:', error);
      throw error;
    }
  }

  /**
   * Verifica o preço de um produto.
   * @param {string} produto - O nome do produto.
   */
  static checkPrice(produto) {
    console.log(`Verificando preço para ${produto}...`);
  }
}

export default Utils;