
function FramingRoute() {
  return (
    <div>
      FRAMING
    </div>
  );
}

export default FramingRoute;
