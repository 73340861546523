
function UploadRoute() {
  return (
    <div>
      UPLOAD
    </div>
  );
}

export default UploadRoute;
