import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import HomeRoute from './routes/01-home/HomeRoute';
import CustomRoute from './routes/02-custom/CustomRoute';
import UploadRoute from './routes/03-upload/UploadRoute';
import FramingRoute from './routes/04-framing/FramingRoute';
import CheckoutRoute from './routes/05-checkout/CheckoutRoute';
import Error from './routes/Error';
import reportWebVitals from './reportWebVitals';



// 1 - configurando router
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <HomeRoute />
      },
      {
        path: "custom",
        element: <CustomRoute />
      },
      {
        path: "upload",
        element: <UploadRoute />
      },
      {
        path: "framing",
        element: <FramingRoute />
      },
      {
        path: "checkout",
        element: <CheckoutRoute />
      },
    ]
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
