import './Header.css';

function Header() {
  return (
    <>
      {/* <!-- ========Welcome Slider============= --> */}
      <section class="section hero-area overlay">
          <div className="block">
          <div className="video-button mb-5">
            <a className="popup-video" href="https://www.youtube.com/watch?v=jrkvirglgaQ">
              <i className="tf-ion-play"></i>
            </a>
          </div>
          <h1>Experience the new reality</h1>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, excepturi. At recusandae sit perferendis
            autem,iste tempora nostrum numquam sapiente!</p>
          <a href="/#services" className="btn btn-transparent smooth-scroll">Explore Us</a>
        </div>
      </section >
    </>
  );
}

export default Header;
